/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* Pallette */
$bckgrdColor: #f6fcff;
$primary: #006bb6;
$primary2: #01518b;
$secondary: #cee7f9;
$truniary: #f1f7fb;
$accent: #97c1db;
$accent2: #b9cd52;
$cardColor: #f6f6f6;
$button: #025087;
$spacer: #d0d0d0;
$link: #cfe4f1;
$linkHover: #438bbe;

$pageTitleColor: #74818b;
$pageTitleBckgrdColorPrimary: #f3f6f9;
$pageTitleBckgrdColorSecondary: #f1f4f7;

$success: #47cf90;
$fail: #bd4b5e;
$cancel: #eda29e;

$black: #000000;
$white: #ffffff;
$grey: #d9d9d9;

/* Type Face */
$fontType: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
$desktopFontSize: 18px;
$tabletFontSize: 18px;
$mobileFontSize: 18px;
$h1:#03548e;
$h2:#10517f;
$h3:#03548e;
$h4:#a3bdd0;
$h5:#03548e;
$h6:#15517b;
$body1: #24292d;
$body2: #9aa0a4;
$subtitle1: #333;
$subtitle2: #333;


/* Layer Cake */ 
$koverseBase: #FE5700;
$koverseShadeDark: #de5409;
$koverseShadeMedium: #f29562;
$koverseShadeLight: #ffc1a0;

$tenjinBase: #2C6CB0;
$tenjinShadeDark: #1c5ca1;
$tenjinShadeMedium: #6aa5e5;
$tenjinShadeLight: #bbdcff;

$missionAccelBase: #4BAAD0;
$missionAccelShadeDark: #3685a4;
$missionAccelShadeMedium: #71c3e3;
$missionAccelShadeLight: #a8e6ff;


/* Globals */ 

/* Body */
$bodyBackgroundColor: $bckgrdColor;
$bodyFontSize: 14px;
$bodyFontFamily: $fontType;

/* Unordered List */ 
$ulListStyle: none;

/* App Wrapper */ 
$appWrapperFlex: 1;
$appWrapperFontSize: 9px;

/* Header */
$appBarBckgrdColor: $primary;

/* Alignment */
$marginCenter: 0 auto;
$marginCenterTxtAlign: center;
$center: center;
$left: left;
$right: right;

/* Page Titles */
$pageTitleColor: $pageTitleColor;
$pageTitleFontSize: 24px;
$pageTitlePaddingLeft: 50px;
$pageTitleUppercase: uppercase;
$pageTitleBckGrdImg: linear-gradient(to left, $pageTitleBckgrdColorPrimary, $pageTitleBckgrdColorSecondary);
$pageTitlePadding: 15px 0 5px 30px;
$pageSubTitleAlign: $center;

/* Spacing */ 
$spacerBckgrdColor: transparent;
$spacerWidth: 100%;
$spacerHeight: 30px;
$pageSpacerBckgrdColor: transparent;
$pageSpacerWidth: 100%;
$pageSpacerHeight: 70px;

/* Dividers */ 
$footerShapeWidth: 100%;
$footerShapeHeight: 4px;
$footerShapeSkew:skew(-10deg);
$footerShapeLinGrad : linear-gradient(to right, #e4f4ff, #0b80d4);

$dividerShapeMargin: 0 auto;
$dividerShapeWidth: 75%;
$dividerShapeHeight: 2px;
$dividerShapeBckgrdImg: linear-gradient(to right, transparent, #95d0fb,transparent);

$lineDividerWidth: 100%;
$lineDividerBckgrdImg: linear-gradient(to bottom, #f1f9ff, #b7d6ea);
$lineDividerHeight: 1px;


/* END Globals */

/* Components */ 

/* Fab */ 
$fabBckgrdColor: $primary;
$fabColor: $white;

/* Tab */ 
$tabBackgrdColor: transparent;
$tabWidth: 25px;
$tabColor: $white;
$tabMargin: 0 auto;
$tabTxtAlign: $center;

/* List */ 
$listBckgrdColor: transparent;
$listItemColor: $primary;

/* Link */ 
$linkColor: $primary;

/* Badge */ 
$badgeBckgrdColor: #03548e;

/* Accordion */ 
$accordionBckgrdColor: #cde2f1;

$accordionDetailsWidth: 85%;
$accordionDetailsMargin: $marginCenter;
$accordionDetailsPadding: 20px;

/* Button */ 
$buttonWidth: 200px;
$buttonHeight: 35px;
$buttonMargin: $marginCenter;

/* Chip */ 
$chipMarginLeft: 10px;
$chipFloat: right;



/* END Components */ 


/* Pages */ 

/* Desktop Version */ 
/* About AI Section */ 
$aboutAiBanner: url('../images/SaicSquareBackground.png');
$aboutAiBannerPosition: bottom right;
$aboutAiBannerRepeat: no-repeat;
$aboutAiBannerSize: 550px 300px;
$aboutAiWidth: 100%;
$aboutAiHeight: 100%;
$aboutAiPaddingBottom: 100px;
$aboutAiTitleColor: $primary;
$aboutAiTitleMarginTop: 350px;
$aboutAiTitleFontSize: 45px;
$aboutAiTitlePosition: absolute;
$aboutAiTitlePaddingLeft: 50px;
$aboutAiLineBackground: linear-gradient(currentColor 0 0) 
bottom left/
var(--underline-width, 0%) 4px
no-repeat;
$aboutAiLineColor: $white;
$aboutAiLineDisplay: inline-block;
$aboutAiLinePadding: 0 2px 2px;
$aboutAiLineTextDecoration: none;
$aboutAiLineTransition: background-size 0.5s;
$aboutAiBannerHoverColor: $primary2;
$aboutAiBannerHoverUnderlineWidth: 100%;


/* Approach AI Section */ 
$approachAIContainerBckgrdImg: linear-gradient(to bottom, #f1f9ff, #e1ebf2);
$approachAIContainerBckPosition: center;
$approachAIContainerBckRepeat: no-repeat;
$approachAIContainerBckgrdSize: cover;
$approachAIContainerHeight: 100%;
$approachAIContainerPadding: 50px 0 50px 0;
$approachAIContainerWordWrap: break-word;
$approachAIContainerTitleMarginTop: -70px;
$approachAIContainerTitleH3Color: $primary;
$approachAIContainerTitleH3Align: left;
$approachAIContainerTitleH3PaddingLeft: 30px;
$approachAIContainerTitleH3FontSize: 30px;


/* Video Carousel */ 
$videoDemoCarouselContainerHeight: auto;
$videoDemoCarouselContainerWidth: 100%;
$videoDemoCarouselContainerPaddingTop: 20px;
$videoDemoCarouselContainerPaddingBottom: 20px;
$videoDemoCarouselContainerMarginTop: 30px;
$videoDemoCarouselSctTleAlign: left;
$videoDemoCarouselSctDescFontSize: $desktopFontSize;
$videoDemoCarouselSctDescMargin: 0 auto;
$videoDemoCarouselSctDescAlign: left;
$videoDemoCarouselSctDescPaddingTop: 30px;
$videoDemoCarouselSctDescTxtMargin: $marginCenter;
$videoDemoCarouselSctDescTxtAlign: left;
$videoDemoCarouselSctDescTxtFontSize: $desktopFontSize;
$videoDemoCarouselSctDescTxtWidth: 95%;
$videoDemoCarouselSctDescTxtWidth: 85%;
$videoDemoCarouselSctDescTxtHeight: auto;
$videoDemoCarouselSctDescTxtBoxShadow: 0 10px 10px 0 #03365b;
$videoDemoCarouselSctDescTxtBrdRadius: 7px;
$videoDemoCarouselSctDescH5Color: $primary;
$videoDemoCarouselSctDescH5FontSize: 22px;
$videoDemoCarouselMarginTop: 15px;
$videoDemoCarouselBorderRadius: 50px;
$videoDemoCarouselHeight: 60px;
$videoDemoCarouselWidth: 150px;
$videoDemoCarouselPadding: 15px;
$videoDemoCarouselBckgrdColor: transparent;
$videoDemoCarouselColor: $primary;
$videoDemoCarouselBorder: 2px solid $primary;
$videoDemoCarouselFontSize: 14px;


/* FAQs Page */ 
$faqContentContainerFlex: 1;
$faqContentContainerWidth: 85%;
$faqContentContainerHeight: 100%;
$faqContentContainerMargin: 25px auto;
$faqContentAccContainerBckgrdImg: linear-gradient(to bottom, #f3f8fc, #e1f1fc);
$faqContentAccContainerPadding: 25px;
$faqContentAccContainerBrdRadius: 10px;
$faqContentAccContainerCategoryFontSize: 24px;
$faqContentAccContainerCategoryColor: #a4bfd0;
$faqContentAccContainerCategoryAlign: left;
$faqContentAccContainerCategoryPadding: 20px;
$faqContentTitleLineWidth: 90%;
$faqContentTitleLineHeight: 2px;
$faqContentTitleLineBckgrdImg: linear-gradient(to left, #f8fcff, #e1f1fc);
$faqContentH5FontSize: 20px;
$faqContentH5Color: $primary2;
$faqContentPMargin: 0 auto;
$faqContentPFontSize: 18px;
$faqContentPWidth: 100%;
$faqContentPColor: #333;
$faqContentPAlign: left;


/* About Us Page */ 
$aboutUsPageContainerFlex: 1;
$aboutUsPageContainerWidth: 90%;
$aboutUsPageContainerHeight: 100%;
$aboutUsPageContainerMargin: 0 auto;
$aboutUsPageTitleColor: $primary2;
$aboutUsPageTitleFontWeight: bold;
$aboutUsPageTitleFontSize: 22px;
$aboutUsPageInfoSectionMargin: $marginCenter;
$aboutUsPageInfoSectionTitleFontSize: 22px;
$aboutUsPageInfoSectionTitleBckgrdImg: linear-gradient(to left, #dbebf6, #fdfeff);
$aboutUsPageInfoSectionTitleColor: $primary;
$aboutUsPageInfoSectionTitlePadding: 15px;
$aboutUsPageInfoSectionDescFontSize: $desktopFontSize;
$aboutUsPageInfoSectionDescColor: #333;
$aboutUsPageInfoSectionDescPadding: 0 25px 0 25px;
$aboutUsPageInfoSectionDescHeight: 230px;
$aboutUsProfileContainerMargin: 0 auto;
$aboutUsProfileContainerProfileInfoMargin: 100px auto;
$aboutUsProfileInfoNameFontSize: 24px;
$aboutUsProfileInfoNameColor: $primary2;
$aboutUsProfileInfoNameFontWeight: bold;
$aboutUsProfileInfoTitleFontSize: 22px;
$aboutUsProfileInfoTitleColor: $primary;
$aboutUsProfileInfoEmailFontSize: $desktopFontSize;
$aboutUsProfileInfoEmailColor: #7a99b0;


/* Demos Video Page */ 
$demoContainerHeight: 100vh;
$demoContainerBckgrdColor: #f6f6f6;
$demoContainerBorderRadius: 10px;
$demoContainerOverflow: auto;
$demoContainerAlign: left;
$demoContainerThbnailLnkHover: $primary;
$demoContainerThbnailLnkBckgrdImg: linear-gradient(to bottom, #dbebf6, #f3f7f9);
$demoContainerMediaTitleHoverColor: $primary;
$demoContainerContentBckgrdImg: linear-gradient(to bottom, #f1f9ff, #f6fcff);
$demoContainerContentHeight: 100%;
$demoContainerH6FontSize: 16px;
$demoContainerSmallColor: $h3;
$demoContainerSmallOpacity: 0.7;
$demoContainerSmallFontSize: 12px;
$demoContainerSmallAlign: left;


/* Layer Cake */

$layerCakeContentborderRadius: 10px;
$layerCakeContentWidth: 97%;
$layerCakeContentFontSize: 18px;






/* END Desktop Version */











