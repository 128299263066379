@import "./variables.scss";
@import "features.scss";

@media (min-width: 1000px) {
  .appTemplate .footerContainer {
    background: #006bb6;
  }

  .appTemplate .container {
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .sectionTitle {
    margin: 0 auto;
    color: $h1;
    font-size: 30px;
    text-align: center;
    padding: 15px;
    font-style: normal;
    font-family: sans-serif;
  }

  /* Header Section */

  .headerBar img {
    width: 120px;
    height: auto;
    margin-top: 5px;
  }

  .headerBar .button {
    margin-top: 10px;
    font-size: 14px;
    float: right;
  }

  /* Footer Section */
  .footer {
    background: #f9f9f9;
    height: 100px;
    clip-path: ellipse(125% 100% at 98.45% 0%);
  }

  .footer img {
    margin-top: 25px;
    float: left;
    width: 105px;
    padding-left: 10px;
  }

  .siteLogo {
    color: $white;
    margin-top: -27px;
    font-size: 18px;
    padding-left: 10px;
  }

  .appWrapper p {
    font-size: 20px;
    margin: 0 auto;
    width: 90%;
    line-height: normal;
    font-family: sans-serif;
  }

  .appWrapper h3 {
    font-size: 36px;
  }

  .MuiButton-root {
    background: $button;
    color: $white;
    font-size: 12px;
    width: 200px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    transform: translatez(0);
  }

  .mobileNavMenu {
    display: none;
  }

  .mobileNav .logo {
    width: 100px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10px;
  }

  /* Navigation Bar */

  .navBar {
    margin: 10px auto;
    font-size: 20px;
    text-align: center;
    font-family: sans-serif;
    font-weight: 400;
  }

  .navBar a {
    text-decoration: none;
    color: $primary;
  }
  .navBar a:link {
    text-decoration: none;
    color: $white;
  }

  .navBar a:hover {
    color: #ffffff;
  }

  .mobileNavCloseMenu {
    background-color: $primary2;
  }

  /* Nav Menu */
  #nav {
    list-style: none inside;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  #nav ul {
    border: 2px solid $primary2;
    border-radius: 10px;
  }

  #nav li {
    display: block;
    position: relative;
    float: left;
    color: $primary2;
    border-radius: 15px;
  }

  #nav li a {
    display: block;
    padding: 0;
    text-decoration: none;
    width: 125px;
    line-height: 35px;
    color: $white;
    padding: 5px;
  }

  #nav li li a {
    font-size: 75%;
    color: $primary2;
    background: transparent;
  }

  #nav li:hover {
    color: $secondary;
  }

  #nav li li a:hover {
    font-size: 80%;
    color: $primary2;
    background: $secondary;
    border-radius: 5px;
  }

  #nav ul {
    position: absolute;
    padding: 0;
    left: 0;
    display: none;
  }

  #nav li:hover ul ul {
    display: none;
  }

  #nav li:hover ul {
    display: block;
  }

  #nav li li:hover ul {
    display: block;
    margin-left: 125px;
    margin-top: -35px;
  }

  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  /* Page Content */

  /* Contact Us Container */
  .contactPageContainer {
    height: 100%;
  }
  .contactUsContentContainer {
   height: 100%;
  }

  .contactUsContentContainer h4 {
    color: $primary;
    font-size: 28px;

  }

  /* About AI Container */
  .aboutAIContainer {

    width: $aboutAiWidth;
    height: 100%;
    padding-bottom: 100px;
  }

  .aboutAIContainer .MuiTypography-root {
    text-align: left;
    padding-left: 50px;
    font-size: 20px;
  }

  .aboutAIContainer img {
    margin-top: -100px;
  }

  .aboutAIContainer .aboutTitle {
    color: #fff;
    font-size: 40px;
    padding-left: 25px;
    margin-top: 275px;
    position: absolute;
  }

  .aboutAIContainer .subTitle {
    color: $primary;
    text-align: left;
    font-size: 30px;
  }

  .aboutAIContainer .aboutTitle .line {
    background: $aboutAiLineBackground;
    display: $aboutAiLineDisplay;
    padding: $aboutAiLinePadding;
    text-decoration: $aboutAiLineTextDecoration;
    transition: $aboutAiLineTransition;
    color: #fff;
  }

  .aboutAIContainer .banner:hover {
    color: $aboutAiBannerHoverColor;
    --underline-width: 100%;
  }

   /* Home Buttons sectiion - Landing Page */ 
   .homeButtons {
    margin: -10px auto;
    width: 100%;
    height: 150px;
  }

  .homeButtons .MuiButton-root {
    height: 65px;
    font-size: 16px;
    padding-top: 18px;
  }

  .homeButtons .MuiButtonGroup-root {
    margin-top:60px;
  }

  /* Apporach AI Container */

  .approachAIContainer {
    background-image: $approachAIContainerBckgrdImg;
    background-position: $approachAIContainerBckPosition;
    background-repeat: $approachAIContainerBckRepeat;
    background-size: $approachAIContainerBckgrdSize;
    padding: $approachAIContainerPadding;
    word-wrap: $approachAIContainerWordWrap;
    background: linear-gradient(-45deg, #94bad8, #cee7f9, #e4f3ff, #fdfeff);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    // margin-top: 125px;
    height: 100%;
  }

  .approachAIContainer img {
  
    border-radius: 10px;
    width: 100%;
    text-align: left;
  }

  .linkBox {
    display: inline-block;
    position: relative;
  
    z-index: 0;
    transition: all .3s ease;
    opacity: 1;
    margin-right:  -1px;
    
    &:hover {
     z-index: 10;
  
     
    }
    
    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      // background: $black;
      top:  50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      opacity: 1;
      
    }
    &:before {
      width: calc(100% + 2px);
      height: 100%;
      z-index: 1;
      transition: height 1s ease, opacity .8s ease;
     
    }
    
    &:after {
      height: calc(100% + 2px);
      width: 100%;
      z-index: 1;
      transition: width 1s ease, opacity .8s ease;
  
    }
    
    &:hover, &:focus{
      &:before {
        transition: height .2s ease , opacity .3s ease;
        height: 85%;
        opacity: .7;
        
      }
      
      &:after {
         transition: width .2s ease,  opacity .3s ease;
         width: 85%;
         opacity: .8;
      }
    }
    
    &__image {
      width: 60%;
      will-change: width;
      position: absolute;
      top:  50%;
      left: 50%;
      transform: translate(-50%, -50%)  scale(1);
      transition: all .5s ease;
      z-index: 2;
    }
  }


  .approachAIContainer .linkBox{
    color: $primary;
    text-decoration: none;
    text-align: center;
  }

  .approachAIContainer .linkBox:visited {
    color: $primary;
    text-decoration: none;
  }

  .approachAIContainer .linkBox:hover{
    border: 1px solid $secondary;
    padding: 20px;
    text-decoration: none;
    text-align: center;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .approachAITitle {
    margin-top: $approachAIContainerTitleMarginTop;
  }

  .approachAIContainer h3 {
    text-align: $approachAIContainerTitleH3Align;
    padding-left: $approachAIContainerTitleH3PaddingLeft;
    font-size: $approachAIContainerTitleH3FontSize;
    color: $approachAIContainerTitleH3Color;
  }


  .approachAIContainer .title {
    color: $primary;
    font-size: 20px;
    text-align: left;
    padding-right: 50px;
  }

  .approachAIContainer .desc {
    width: 95%;
    text-align: left;
  }

  .approachAIContainer .container {
    text-align: center;
  }
  

  /* Video Carosuel Home Section */
  .videoDemoCarouselContainer {
    height: $videoDemoCarouselContainerHeight;
    width: $videoDemoCarouselContainerWidth;
    padding-top: $videoDemoCarouselContainerPaddingTop;
    padding-bottom: $videoDemoCarouselContainerPaddingBottom;
    margin-top: $videoDemoCarouselContainerMarginTop;
    background: #f0f0f0;
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .videoDemoCarouselContainer .sectionTitle {
    text-align: $videoDemoCarouselSctTleAlign;
  }

  .videoDemoCarouselContainer .sectionDescription {
    font-size: $videoDemoCarouselSctDescFontSize;
    margin: $videoDemoCarouselSctDescMargin;
    text-align: $videoDemoCarouselSctDescAlign;
  }

  .videoDemoCarouselContainer .sectionDescription .title {
    padding-top: $videoDemoCarouselSctDescPaddingTop;
  }

  .videoDemoCarouselContainer .sectionDescription .text {
    margin: $videoDemoCarouselSctDescTxtMargin;
    text-align: $videoDemoCarouselSctDescTxtAlign;
    font-size: $videoDemoCarouselSctDescTxtFontSize;
    width: $videoDemoCarouselSctDescTxtWidth;
  }

  .videoDemoCarouselContainer video {
    width: $videoDemoCarouselSctDescTxtWidth;
    height: $videoDemoCarouselSctDescTxtHeight;
    box-shadow: $videoDemoCarouselSctDescTxtBoxShadow;
    border-radius: $videoDemoCarouselSctDescTxtBrdRadius;
  }

  .videoDemoCarouselContainer h5 {
    color: $primary;
    font-size: $videoDemoCarouselSctDescH5FontSize;
  }

  .videoDemoCarouselContainer .button {
    margin-top: $videoDemoCarouselMarginTop;
    border-radius: $videoDemoCarouselBorderRadius;
    height: $videoDemoCarouselHeight;
    width: $videoDemoCarouselWidth;
    padding: $videoDemoCarouselPadding;
    background-color: $videoDemoCarouselBckgrdColor;
    color: $videoDemoCarouselColor;
    border: $videoDemoCarouselBorder;
    font-size: $videoDemoCarouselFontSize;
  }

  /* Contact Us */ 
  .contactUsContentContainer {
    margin: 10px auto;
    height: 75vh;
  }

  .contactUsContentContainer h4 {
    color: $primary2;

  }

  .contactUsContentContainer  .MuiPaper-root{
    padding: 25px;
  }


  /* FAQ Page Section */

  .faqContentContainer {
    flex-grow: $faqContentContainerFlex;
    width: $faqContentContainerWidth;
    height: $faqContentContainerHeight;
    margin: $faqContentContainerMargin;
  }

  .faqContentContainer .accordionContainer {
    background-image: $faqContentAccContainerBckgrdImg;
    padding: $faqContentAccContainerPadding;
    border-radius: $faqContentAccContainerBrdRadius;
  }

  .faqContentContainer .category {
    font-size: $faqContentAccContainerCategoryFontSize;
    color: $faqContentAccContainerCategoryColor;
    text-align: $faqContentAccContainerCategoryAlign;
    padding: $faqContentAccContainerCategoryPadding;
  }

  .faqContentContainer .titleLine {
    width: $faqContentTitleLineWidth;
    height: $faqContentTitleLineHeight;
    background-image: $faqContentTitleLineBckgrdImg;
  }

  .faqContentContainer h5 {
    font-size: $faqContentH5FontSize;
    color: $faqContentH5Color;
  }

  .faqContentContainer p {
    margin: $faqContentPMargin;
    font-size: $faqContentPFontSize;
    width: $faqContentPWidth;
    color: $faqContentPColor;
    text-align: $faqContentPAlign;
  }

  /* partnersContainer */ 
  .partnersContainer {
    margin: 10px auto;
    padding-bottom: 100px;

  }

  .partnersContainer h5 {
    color: $primary;
    text-align: left;
    font-size: 26px;
  }


  /* About Us Section */
  .aboutUsPageContainer {
    flex-grow: $aboutUsPageContainerFlex;
    width: $aboutUsPageContainerWidth;
    height: $aboutUsPageContainerHeight;
    margin: $aboutUsPageContainerMargin;
  }

  .aboutUsPageContainer .pageTitle {
    color: $aboutUsPageTitleColor;
    font-weight: $aboutUsPageTitleFontWeight;
    font-size: $aboutUsPageTitleFontSize;
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: $aboutUsPageInfoSectionMargin;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: $aboutUsPageInfoSectionTitleFontSize;
    background-image: $aboutUsPageInfoSectionTitleBckgrdImg;
    color: $aboutUsPageInfoSectionTitleColor;
    padding: $aboutUsPageInfoSectionTitlePadding;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: $aboutUsPageInfoSectionDescFontSize;
    color: $aboutUsPageInfoSectionDescColor;
    padding: $aboutUsPageInfoSectionDescPadding;
    height: $aboutUsPageInfoSectionDescHeight;
  }

  .aboutUsPageContainer .profileContainer {
    margin: $aboutUsProfileContainerMargin;
  }

  .profileContainer .profileInfo {
    margin: $aboutUsProfileContainerProfileInfoMargin;
  }

  .profileContainer .profileInfo .name {
    font-size: $aboutUsProfileInfoNameFontSize;
    color: $aboutUsProfileInfoNameColor;
    font-weight: $aboutUsProfileInfoNameFontWeight;
  }

  .profileContainer .profileInfo .title {
    font-size: $aboutUsProfileInfoTitleFontSize;
    color: $aboutUsProfileInfoTitleColor;
  }

  .profileContainer .profileInfo .email {
    font-size: $aboutUsProfileInfoEmailFontSize;
    color: $aboutUsProfileInfoEmailColor;
  }

  /* Demos Videos Page Section */
  .demoContainer .videoThumbnailContainer {
    height: $demoContainerHeight;
    background: $demoContainerBckgrdColor;
    border-radius: $demoContainerBorderRadius;
    overflow: $demoContainerOverflow;
    text-align: $demoContainerAlign;
  }

  .demoContainer .videoThumbnailContainer .Link:hover {
    color: $demoContainerThbnailLnkHover;
    background-image: linear-gradient(to bottom, #dbebf6, #f3f7f9);
  }

  .demoContainer .videoThumbnailContainer .listMediaTitle:hover {
    color: $demoContainerMediaTitleHoverColor;
  }

  .demoContainer .contentContainer {
    background-image: $demoContainerContentBckgrdImg;
    height: $demoContainerContentHeight;
  }

  .demoContainer h6 {
    font-size: $demoContainerH6FontSize;
  }

  .demoContainer small {
    color: $demoContainerSmallColor;
    opacity: $demoContainerSmallOpacity;
    font-size: $demoContainerSmallFontSize;
    text-align: $demoContainerSmallAlign;
  }

  /* Offerings Page */
  .offeringsContentContainer {
    height: 100%;
    padding-bottom: 100px;
  }

  .offeringsContentContainer a {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:link {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:hover {
    text-decoration: none;
    color: #74add5;
    font-size: 24px;
  }

  .offeringsContentContainer .container {
    margin: 0 auto;
    padding: 0 50px 0 50px;
  }

  .offeringsContentContainer .container .image {
    width: 200px;
    text-align: left;
  }

  .offeringsContentContainer .title {
    text-align: left;
    margin: 0 auto;
    width: 75%;
  }

  .offeringsContentContainer .desc {
    text-align: left;
    width: 100%;
    margin: 0 auto;
  }

  /* Layer Cake */

  .layerCakeContent {
    border-radius: $layerCakeContentborderRadius;
    width: 97%;
    font-size: $layerCakeContentFontSize;
    margin: 25px auto;
    font-family: sans-serif;
    height: 100%;
  }

  .layerCakeContentSideA {
    font-size: 18px;
    padding-bottom: 50px;
  }

  .layerCakeContentSideB {
    // border-radius: 0 10px 10px 0;
    background-image: radial-gradient(#f2f2f2 1.5px, transparent 0.5px);
    background-size: 10px 10px;
    background-color: #ffffff;
    padding-bottom: 50px;
  }

  .layerCakeLayerTitle {
    margin-top: 25px;
    padding-left: 50px;
    text-align: left;
    font-size: 25px;
    color: $white;
  }

  .layerCakeContentSideA .desc {
    width: 80%;
    margin: 0 auto;
    font-size: 18px;
    text-align: left;
    color: $white;
  }

  .layerCakeContentSideB .header {
    text-align: left;
    font-weight: 600;
    width: 90%;
    margin: 25px auto;
  }

  .layerLogo {
    width: 200px;
  }

  /* Components */
  .Card {
    text-align: center;
    background: transparent;
  }

  .Card .cardText {
    font-size: $desktopFontSize;
    width: 100%;
    word-wrap: break-word;
    text-align: left;
    color: $body1;
    margin: 0 auto;
    padding: 0 30px 0 30px;
  }

  .Card h5 {
    width: 95%;
    height: 90px;
    font-size: 16px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: $white;
    word-wrap: break-word;
    font-weight: 600;
  }

  /* Profile Card Section */
  .ProfileCard {
    width: 100%;
    text-align: center;
  }

  .ProfileCard img {
    text-align: center;
    margin: 25px auto;
    height: 200px;
    border-radius: 10px;
    box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);
    transform: rotate(2deg);
  }

 /* PartnersCard */ 
 .PartnersCard {

   text-align: left;
   height: 350px;
 }

 .PartnersCard img{
  padding-top: 25px;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}


  .badgeComponentContainer {
    float: left;
    margin: 0;
    padding: 20px;
  }

  #gear1,
  #gear2 {
    color: #888;
    display: block;
    float: left;
    position: absolute;
    margin: 25px 0 0 0;
  }

  #gear1 {
    top: 25px;
    left: 50px;
  }

  #gear2 {
    left: 190px;
  
  }

  .spin {
    -webkit-animation: spin 7s linear infinite;
    -moz-animation: spin 7s linear infinite;
    animation: spin 7s linear infinite;
  }

  .spin-back {
    -webkit-animation: spin-back 7s linear infinite;
    -moz-animation: spin-back 7s linear infinite;
    animation: spin-back 7s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin-back {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes spin-back {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin-back {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
}
